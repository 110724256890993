import { useEffect, useLayoutEffect } from 'react';
import { KeyboardShortcutRegistry } from 'ts/base/scaffolding/KeyboardShortcutRegistry';
import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';

/** Provides a keyboard shortcut registry reference. */
export function useKeyboardShortcutRegistry(viewDescriptor: ViewDescriptor): void {
	useLayoutEffect(() => {
		const listener = (event: KeyboardEvent) => KeyboardShortcutRegistry.INSTANCE.handleKeyDown(event);
		window.addEventListener('keydown', listener);
		return () => window.removeEventListener('keydown', listener);
	}, []);
	useEffect(() => {
		KeyboardShortcutRegistry.INSTANCE.clearShortCuts();
	}, [viewDescriptor]);
}

/** Registers a keyboard shortcut while the current component is mounted. */
export function useKeyboardShortcut(
	shortcut: string,
	description: string,
	callback: () => void,
	preventDefault = true
): void {
	useEffect(
		() => KeyboardShortcutRegistry.INSTANCE.registerShortcut(shortcut, description, callback, preventDefault),
		[callback, description, preventDefault, shortcut]
	);
}
